<template>
  <c-box
    v-if="show"
    mb="1rem"
    px="8px"
  >
    <PaymentMethod
      v-model="selectedPayment"
      :detail-payment="detailPayment"
      :loading="loading"
      @handleChangePaymentMethod="handleChangePaymentMethod"
      @handleChangePaymentMethodId="handleChangePaymentMethodId"
    />

    <c-flex justify-content="center">
      <c-button
        background="#008C81"
        color="#FFF"
        w="400px"
        font-family="Roboto"
        font-size="16px"
        font-weight="500"
        border-radius="50px"
        p="16px 50px"
        h="50px"
        :disabled="isDisabledPayment"
        :is-loading="loading"
        loading-text="Memperbarui Pembayaran"
        @click="submitPayment()"
      >
        {{ transaction.paymentMethodId ? 'Ubah Metode Pembayaran' : 'Lanjutkan Pembayaran' }}
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
import _ from 'lodash'
import PaymentMethod from '@/components/payment-method.vue'
import { mapActions } from 'vuex'
import { formatCurrency } from '@/utils/format-currency'

export default {
  name: 'SelectPayment',
  components: {
    PaymentMethod,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    transactionId: {
      type: String,
      default: '',
    },
    transaction: {
      type: [Object, null],
      required: true,
    },
  },
  data() {
    return {
      selectedPayment: '',
      detailPayment: {
        isCreditCard: null,
        additionalInfo: {
          cardNumber: '',
          cardCVV: '',
          expMonth: '',
          expYear: '',
        },
      },
      loading: false, // loading for submitting payment
    }
  },
  computed: {
    getTotal() {
      return this.transaction.total ? formatCurrency(this.transaction.total) : '-'
    },
    isCreditCardValid() {
      const { cardNumber, cardCVV, expMonth, expYear } = this.detailPayment.additionalInfo
      if (cardNumber && cardCVV && expMonth && expYear) {
        return true
      }
      return false
    },
    isUsingCreditCard() {
      return this.detailPayment.isCreditCard === 1
    },
    isDisabledPayment() {
      if (this.isUsingCreditCard) return !this.isCreditCardValid
      if (this.selectedPayment && !_.isEmpty(this.transaction) && this.selectedPayment != this.transaction.paymentMethodId) {
        return false
      }
      return true
    },
  },
  watch: {
    transaction: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue) return
        if (newValue?.id == this.transactionId && newValue?.paymentMethodId && newValue?.paymentMethodId != oldValue?.paymentMethodId) {
          this.selectedPayment = newValue.paymentMethodId
        }
      },
    },
    show: {
      handler(val) {
        if (!val && this.transaction?.paymentMethodId) {
          this.selectedPayment = this.transaction.paymentMethodId
        }
      },
    },
  },
  methods: {
    ...mapActions({
      createMidtransCharge: 'checkout/createMidtransCharge',
      updateMidtransCharge: 'checkout/updateMidtransCharge',
    }),
    handleChangePaymentMethod(detailPayment) {
      this.detailPayment = detailPayment
    },
    handleChangePaymentMethodId(paymentMethodId) {
      this.selectedPayment = paymentMethodId
    },
    submitPayment() {
      this.loading = true
      const isCreditCartPayment = this.detailPayment.isCreditCard === 1
      const isUpdatePayment = !!this.transaction.paymentMethodId
      if (isCreditCartPayment) {
        const payload = {
          transactionId: this.transactionId,
          paymentMethodId: this.selectedPayment,
          flagRoute: 'landingpage',
          ...this.detailPayment.additionalInfo,
        }
        if (isUpdatePayment) {
          this.updateMidtransCharge(payload)
            .then((res) => {
              if (res.redirectUrl) {
                this.$router.push({
                  name: 'client.checkout.redirect-midtrans-3ds',
                  query: {
                    transactionId: res?.transactionId,
                    redirectUrl: res.redirectUrl,
                    flagRoute: 'buy-program',
                  },
                })
              } })
            .catch((err) => {
              this.$toast({
                status: 'error',
                title: `Error: ${err.data.message?.status_code}`,
                description: err.data.message.status_message ? err.data.message.status_message : err.data.message,
                duration: 5000,
              })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.createMidtransCharge(payload)
            .then((res) => {
              if (res.redirectUrl) {
                this.$router.push({
                  name: 'client.checkout.redirect-midtrans-3ds',
                  query: {
                    transactionId: res?.transactionId,
                    redirectUrl: res.redirectUrl,
                    flagRoute: 'buy-program',
                  },
                })
              }
            })
            .catch((err) => {
              this.$toast({
                status: 'error',
                title: `Error: ${err.data.message?.status_code}`,
                description: err.data.message.status_message ? err.data.message.status_message : err.data.message,
                duration: 5000,
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
        return
      }

      // API for bank transfer and gopay
      const payload = {
        transactionId: this.transactionId,
        paymentMethodId: this.selectedPayment,
        flagRoute: 'landingpage',
      }
      if (this.transaction.paymentMethodId) {
        this.updateMidtransCharge(payload)
          .then(() => {
            setTimeout(async() => {
              this.$emit('afterSelectPayment')
              this.loading = false
            }, 1000)
          })
          .catch((err) => {
            this.$toast({
              status: 'error',
              title: `Error: ${err.data.message?.status_code}`,
              description: err.data.message.status_message ? err.data.message.status_message : err.data.message,
              duration: 5000,
            })
            this.loading = false
          })
      } else {
        this.createMidtransCharge(payload)
          .then(() => {
            setTimeout(async() => {
              this.$emit('afterSelectPayment')
              this.loading = false
            }, 1000)
          })
          .catch((err) => {
            this.$toast({
              status: 'error',
              title: `Error: ${err.data.message?.status_code}`,
              description: err.data.message.status_message ? err.data.message.status_message : err.data.message,
              duration: 5000,
            })
          })
      }
    },
  },
}
</script>