import { render, staticRenderFns } from "./payment.vue?vue&type=template&id=0f75b5fa&scoped=true&"
import script from "./payment.vue?vue&type=script&lang=js&"
export * from "./payment.vue?vue&type=script&lang=js&"
import style0 from "./payment.vue?vue&type=style&index=0&id=0f75b5fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f75b5fa",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox})
