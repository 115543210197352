<template>
  <c-box
    px="8px"
  >
    <c-box
      flex-direction="row"
      justify-content="space-between"
      align-items="center"
      background="#FFFFFF"
      box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
      border-radius="12px"
      :padding="['16px', '24px']"
      :mb="['1rem', '1.5rem']"
    >
      <c-flex width="100%">
        <c-text
          font-family="Roboto"
          :font-size="['16px', '20px']"
          color="#333"
          :font-weight="['500', '700']"
        >
          No. Pesanan
        </c-text>
      </c-flex>
      <c-box
        width="100%"
        border-bottom="1px solid #C4C4C4"
        my="10px"
      />
      <c-text
        font-family="Roboto"
        :font-size="['14px', '16px']"
        color="#333"
        font-weight="400"
      >
        {{ transaction.invoiceNumber }}
      </c-text>
    </c-box>

    <c-box
      flex-direction="row"
      justify-content="space-between"
      align-items="center"
      background="#FFFFFF"
      box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
      border-radius="12px"
      :padding="['0px', '24px']"
      :mb="['1rem', '1.5rem']"
    >
      <c-flex
        width="100%"
        justify-content="space-between"
        align-items="center"
        :mb="['0px', '10px']"
        :p="['16px', '0px']"
      >
        <c-text
          font-family="Roboto"
          :font-size="['16px', '20px']"
          color="#333"
          :font-weight="['500', '700']"
        >
          Detail Pembayaran
        </c-text>
      </c-flex>
      <c-flex
        mb="1rem"
        :px="['1rem', '0']"
        align-items="center"
      >
        <c-image
          :src="transaction.productPhoto"
          :h="['100px', '150px']"
          w="auto"
          border-radius="12px"
          flex-shrink="0"
          :mr="['10px', '24px']"
        />
        <c-box align-self="center">
          <c-text
            font-family="Roboto"
            :font-size="['16px', '28px']"
            :font-weight="[500, 700]"
            color="#333"
          >
            {{ transaction.productName }} {{ transaction.productServiceName }} ({{ transaction.duration }} Hari)
          </c-text>
          <c-box
            v-chakra="{
              ':hover': {
                textDecoration: 'underline',
              }
            }"
            as="router-link"
            font-family="Roboto"
            :font-size="['12px', '16px']"
            font-weight="400"
            color="#333"
            :to="getHandleMore({
              isKonsultasi: transaction?.productService?.isKonsultasi == 1,
              isExtend: transaction?.productService?.isExtend == 1,
              productId: transaction?.productId,
              productSlug: transaction?.slug,
            })"
          >
            Selengkapnya
          </c-box>
          <c-text
            font-family="Roboto"
            :font-size="['18px', '20px']"
            font-weight="700"
            color="#008C81"
          >
            {{ formatCurrency(transaction.productPrice) }}
          </c-text>
        </c-box>
      </c-flex>
      <c-box
        :border-top="['1px solid #C4C4C4', 'none']"
        w="100%"
      />
      <c-box
        v-if="transaction.billerCode"
        :border-top="['none', '1px solid #C4C4C4']"
        p="16px"
      >
        <c-text
          font-family="Roboto"
          :font-size="['16px', '20px']"
          color="#333"
          :font-weight="['500', '700']"
          :mb="['4px', '8px']"
        >
          Kode Perusahaan
        </c-text>
        <c-flex
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-family="Roboto"
            :font-size="['14px', '16px']"
            color="#333"
            font-weight="400"
          >
            {{ transaction.billerCode }}
          </c-text>
          <input
            ref="company_code"
            type="hidden"
            :value="transaction.billerCode"
          >
          <c-box
            cursor="pointer"
            @click="copyClipboard('company_code')"
          >
            <c-image
              :src="require('@/assets/icons/icon-copy.svg')"
              :width="['20px', '25px']"
              :height="['20px', '25px']"
            />
          </c-box>
        </c-flex>
      </c-box>
      <c-box
        v-if="transaction.paymentMethodCode != 'gopay'"
        :border-top="['none', '1px solid #C4C4C4']"
        p="16px"
      >
        <c-text
          font-family="Roboto"
          :font-size="['16px', '20px']"
          color="#333"
          :font-weight="['500', '700']"
          :mb="['4px', '8px']"
        >
          No. Virtual Account
        </c-text>
        <c-flex
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-family="Roboto"
            :font-size="['14px', '16px']"
            color="#333"
            font-weight="400"
          >
            {{ transaction.vaNumber }}
          </c-text>
          <input
            ref="va_number"
            type="hidden"
            :value="transaction.vaNumber"
          >
          <c-box
            cursor="pointer"
            @click="copyClipboard('va_number')"
          >
            <c-image
              :src="require('@/assets/icons/icon-copy.svg')"
              :width="['20px', '25px']"
              :height="['20px', '25px']"
            />
          </c-box>
        </c-flex>
      </c-box>
      <!-- <c-box :borderTop="['none', '1px solid #C4C4C4']" p="16px">
        <c-text
          fontFamily="Roboto"
          :fontSize="['16px', '20px']"
          color="#333"
          :fontWeight="['500', '700']"
          :mb="['4px', '8px']"
        >
          Melalui {{ transaction.paymentMethodCode != 'gopay' ? 'Virtual' : '' }} Account
        </c-text>
        <c-flex alignItems="center" justifyContent="space-between">
          <c-text fontFamily="Roboto" :fontSize="['14px', '16px']" color="#333" fontWeight="400">
            {{ transaction.paymentMethodName }}
          </c-text>
          <c-image v-if="transaction.image" :src="transaction.image" :height="['25px', '35px']" />
        </c-flex>
      </c-box> -->
      <c-box
        :border-top="['none', '1px solid #C4C4C4']"
        p="16px"
      >
        <c-text
          font-family="Roboto"
          :font-size="['16px', '20px']"
          color="#333"
          :font-weight="['500', '700']"
          :mb="['4px', '8px']"
        >
          Total Pembayaran
        </c-text>
        <c-flex
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-family="Roboto"
            :font-size="['18px', '28px']"
            color="#008C81"
            font-weight="700"
          >
            {{ getTotal }}
          </c-text>
          <input
            ref="total"
            type="hidden"
            :value="transaction.total"
          >
          <c-box
            cursor="pointer"
            @click="copyClipboard('total')"
          >
            <c-image
              :src="require('@/assets/icons/icon-copy.svg')"
              :width="['20px', '25px']"
              :height="['20px', '25px']"
              cursor="pointer"
            />
          </c-box>
        </c-flex>
      </c-box>
    </c-box>

    <c-box
      flex-direction="row"
      justify-content="space-between"
      align-items="center"
      background="#FFFFFF"
      box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
      border-radius="12px"
      :padding="['0px', '24px']"
      :mb="['1rem', '1.5rem']"
    >
      <c-flex
        width="100%"
        :mb="['0px', '10px']"
        :p="['16px', '0px']"
      >
        <c-text
          font-family="Roboto"
          :font-size="['16px', '20px']"
          color="#333"
          :font-weight="['500', '700']"
        >
          Langkah Pembayaran
        </c-text>
      </c-flex>
      <c-box
        v-if="instruction.length > 0"
        border-top="1px solid #C4C4C4"
        px="16px"
        pt="16px"
        :pb="['16px', '0px']"
      >
        <c-accordion :allow-toggle="true">
          <c-accordion-item
            v-for="(item, i) in instruction"
            :key="i"
            border="none"
            mb="10px"
          >
            <c-accordion-header
              background-color="#F2F2F2"
              border-radius="8px"
            >
              <c-box
                flex="1"
                text-align="left"
                font-family="Roboto"
                :font-size="['16px', '20px']"
                :font-weight="['500', '700']"
              >
                {{ item.title }}
              </c-box>
              <c-accordion-icon />
            </c-accordion-header>
            <c-accordion-panel
              v-if="item.instruction.length > 0"
              pb="2"
            >
              <c-image
                v-if="transaction.qrCodeLink && item.type == 'qrcode'"
                :src="transaction.qrCodeLink"
                max-width="250px"
                mx="auto"
                mb="8px"
              />
              <c-text
                mb="4px"
                :font-size="['14px', '16px']"
              >
                Petunjuk Pembayaran dengan {{ item.title }}
              </c-text>
              <c-list
                as="ol"
                style-type="decimal"
                style-pos="outside"
                ml="1rem"
              >
                <c-list-item
                  v-for="(step, index) in item.instruction"
                  :key="index"
                  font-family="Roboto"
                  :font-size="['14px', '16px']"
                  v-html="step"
                />
              </c-list>
              <c-flex justify-content="center">
                <c-button
                  v-if="transaction.deepLink && item.type == 'deeplink'"
                  variant="outline"
                  color="#008C81"
                  border-color="#008C81"
                  border-radius="32px"
                  mt="16px"
                  :w="['100%', '400px']"
                  :font-size="['14px', '16px']"
                  as="a"
                  :href="transaction.deepLink"
                  target="_blank"
                  h="50px"
                >
                  Lanjutkan Pembayaran
                </c-button>
              </c-flex>
            </c-accordion-panel>
          </c-accordion-item>
        </c-accordion>
      </c-box>
    </c-box>

    <c-box
      w="100%"
      text-align="center"
      :pb="['16px', '0px']"
    >
      <c-button
        background="#008C81"
        color="#FFF"
        py="10px"
        border-radius="60px"
        font-size="16px"
        font-weight="500"
        font-family="Roboto"
        :w="['100%', '400px']"
        h="50px"
        :is-loading="loading"
        loading-text="Memperbarui Pembayaran"
        @click="$emit('checkPayment')"
      >
        Cek Pembayaran
      </c-button>
    </c-box>
  </c-box>
</template>

<script>
import { formatCurrency } from '@/utils/format-currency'

export default {
  name: 'DetailTransaction',
  props: {
    transactionId: {
      type: String,
      default: '',
    },
    transaction: {
      type: [Object, null],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      instruction: [],
    }
  },
  computed: {
    getTotal() {
      return this.transaction.total ? formatCurrency(this.transaction.total) : '-'
    },
  },
  watch: {
    transaction: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue) return
        if (newValue.paymentInstructions && newValue.paymentInstructions.length > 0) {
          let instructions = newValue.paymentInstructions
          const isDeepLink = instructions.some((el) => el.type == 'deeplink')
          if (!this.$isMobile() && isDeepLink) {
            instructions = instructions.filter((el) => el.type != 'deeplink')
          }
          const jsonString = JSON.stringify(instructions)
          const replacedJsonString = jsonString
            .replace(/\W{}/g, '')
            .replace(/{{va_number}}/g, newValue.vaNumber)
            .replace(/{{kode_perusahaan}}/g, newValue.billerCode)
          this.instruction = JSON.parse(replacedJsonString)
        }

        if (newValue?.paymentMethodId != oldValue?.paymentMethodId) {
          if (newValue.deepLink && this.$isMobile() && newValue.paymentInstructions.some((el) => el.type == 'deeplink')) {
            window.open(newValue.deepLink)
          }
        }
      },
    },
  },
  methods: {
    formatCurrency,
    getHandleMore({ isKonsultasi, isExtend, productId, productSlug }) {
      if (isKonsultasi) {
        return {
          name: 'client.program.consultation',
        }
      }
      if (isExtend) {
        return {
          name: 'client.program.extend',
        }
      }
      return {
        name: 'client.program.detail',
        params: { id: productSlug ? productSlug : productId },
      }
    },
    copyClipboard(ref) {
      const el = this.$refs[ref]
      try {
        el.setAttribute('type', 'text')
        el.select()
        document.execCommand('copy')
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Berhasil disalin',
          duration: 2000,
        })
      } catch (err) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: 'Oops, unable to copy',
          duration: 2000,
        })
      }
      el.setAttribute('type', 'hidden')
    },
    handleShowDetailProgram() {
      this.$router.push({
        name: 'client.program',
      })
    }, 
  },
}
</script>
