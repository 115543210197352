<template>
  <c-box
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#fff', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['auto', '74vh']"
  >
    <Stepper
      margin-top="16px"
      :margin-bottom="['12px', '20px']"
      :steps="[
        {
          label: 'Informasi Pribadi',
        },
        {
          label: 'Periksa',
          isCurrentStep: true,
        },
        {
          label: 'Pembayaran',
        },
      ]"
    />

    <BaseText
      size-mobile="18px"
      size-desktop="28px"
      text-align="center"
    >
      Pembayaran
    </BaseText>
    
    <c-box
      :padding="['16px 8px', '1rem']"
      :max-width="['auto', '900px']"
      margin="auto"
    >
      <c-flex
        v-if="isAlreadySelectPayment"
        w="100%"
        background="#FDEDCB"
        padding="8px"
        :mb="['1rem', '1.5rem']"
        align-items="center"
        justify-content="center"
      >
        <c-image
          :src="require('@/assets/icons/icon-watch.svg')"
          :width="['20px', '25px']"
          :height="['20px', '25px']"
          :mr="['8px', '16px']"
        />
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          font-weight="400"
          color="#111"
        >
          {{ countdown }}
        </c-text>
      </c-flex>
      
      <c-flex
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
        border-radius="12px"
        background-color="#C7F9E3"
        :p="['10px 20px', '1rem 2rem']"
        mb="1rem"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="20px"
        >
          Metode Pembayaran
        </BaseText>

        <c-button
          v-if="isAlreadySelectPayment"
          variant="link"
          font-family="Roboto"
          color="#D32737"
          :font-size="['12px', '16px']"
          font-weight="400"
          @click="handleChangePaymentMethod"
        >
          {{ showPaymentMethod ? 'Kembali' : 'Ganti Metode' }}
        </c-button>
      </c-flex>

      <c-box
        v-if="isAlreadySelectPayment && !showPaymentMethod"
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
        background="#FFFFFF"
        box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
        border-radius="12px"
        :padding="['16px', '24px']"
        :mb="['1rem', '1.5rem']"
        mx="8px"
      >
        <c-flex width="100%">
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
          >
            Pembayaran Melalui
          </c-text>
        </c-flex>
        <c-box
          width="100%"
          border-bottom="1px solid #C4C4C4"
          my="10px"
        />
        <c-flex
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-family="Roboto"
            :font-size="['14px', '16px']"
            color="#333"
            font-weight="400"
          >
            {{ transaction.paymentMethodName }}
          </c-text>
          <c-image
            v-if="transaction.image"
            :src="transaction.image"
            :height="['25px', '35px']"
          />
        </c-flex>
      </c-box>

      <SelectPayment 
        :show="!isAlreadySelectPayment || showPaymentMethod"
        :transaction-id="transactionId"
        :transaction="transaction"
        @afterSelectPayment="handleAfterSelectPayment"
      />

      <c-flex
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
        border-radius="12px"
        background-color="#C7F9E3"
        :p="['10px 20px', '1rem 2rem']"
        mb="1rem"
        @click="toggleDetail"
      >
        <BaseText 
          size-mobile="16px" 
          size-desktop="20px"
        >
          Pembayaran
        </BaseText>
        
        <inline-svg
          :src="require('@/assets/icons/icon-arrow-down.svg')"
          fill="#333333"
          class="icon-payment"
          :style="showDetailTransaction ? 'transform: scaleY(-1)' : ''"
        />
      </c-flex>

      <DetailTransaction 
        v-if="isAlreadySelectPayment && showDetailTransaction"
        :transaction-id="transactionId"
        :transaction="transaction"
        :loading="loading"
        @checkPayment="checkPayment"
      />
    </c-box>

    <ModalConfirm 
      title="Apa kamu yakin ingin mengganti metode pembayaran?"
      :is-open="isConfirm"
      @close="onCloseConfirm"
      @submit="onSubmitConfirm()"
    />

    <ModalError
      :is-open="isOpenPaymentNotDone"
      :image="require('@/assets/images/illustration-8-raw.jpg')"
      title="Pembayaran belum diterima"
      note="Pembayaranmu belum diterima, silahkan lakukan pembayaran. Jika kamu sudah melakukan pembayaran silahkan cek sekala berkala"
      button-text="Kembali"
      :button-icon="false"
      :button-action="() => { handleClosePaymentNotDone() }"
      @close="handleClosePaymentNotDone"
    />
  </c-box>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import Stepper from '@/components/elements/stepper.vue'
import BaseText from '@/components/elements/base-text.vue'
import SelectPayment from '@/views/buy-program/_widgets/select-payment'
import DetailTransaction from '@/views/buy-program/_widgets/detail-transaction'
import { mapActions, mapGetters } from 'vuex'
import ModalError from '@/components/widgets/modal-error.vue'
import ModalConfirm from '@/components/widgets/modal-confirm'

export default {
  name: 'BuyPaymentPage',
  components: {
    Stepper,
    BaseText,
    SelectPayment,
    DetailTransaction,
    ModalError,
    ModalConfirm,
  },
  data() {
    return {
      transactionId: this.$route.query.transaction_id,
      isAlreadySelectPayment: false,
      showPaymentMethod: true,
      showDetailTransaction: false,
      initInterval: null,
      countdownInterval: null,
      isOpenPaymentNotDone: false,
      loading: false,
      isExpired: false,
      countdown: 'Selesaikan pembayaran dalam ',
      isConfirm: false,
    }
  },
  computed: {
    ...mapGetters({
      transaction: 'checkout/transaction',
    }),
    _seconds() {
      return 1000
    },
    _minutes() {
      return 1000 * 60
    },
    _hours() {
      return 60000 * 60
    },
    _days() {
      return 3600000 * 24
    },
  },
  async created() {
    await this.init()
    if (this.transaction.paymentMethodId) {
      this.showPaymentMethod = false
      this.showDetailTransaction = true
      if (this.transaction.status == 'pending') {
        this.createInitInterval()
        this.showCountdown()
      }
    }
  },
  beforeDestroy() {
    this.destoryAllInterval()
  },
  methods: {
    ...mapActions({
      getTransaction: 'checkout/getTransaction',
    }),
    handleChangePaymentMethod() {
      if (this.showPaymentMethod) {
        this.showPaymentMethod = false
        this.showDetailTransaction = true
      } else {
        this.onOpenConfirm()
      }
    },
    async handleAfterSelectPayment() {
      this.destoryAllInterval()
      await this.init()
      this.showPaymentMethod = false
      this.showDetailTransaction = true
      if (this.transaction.paymentMethodId) { 
        this.createInitInterval()
        this.showCountdown()
      }
    },
    async init() {
      try {
        if (!this.transactionId) {
          this.$toast({
            status: 'warning',
            title: 'Peringatan',
            description: 'Transaksi tidak ditemukan',
            duration: 2000,
          })
          this.$router.push('/')
        }
        const res = await this.getTransaction(this.transactionId)
        if (_.isEmpty(res.data)) {
          this.$router.push('/')
        }
        if (res.data.status == 'done') {
          window.fbq('track', 'PaymentSuccess')
          this.$toast({
            status: 'success',
            title: 'Berhasil',
            description: 'Transaksi sudah selesai.',
            duration: 2000,
          })
          this.$router.push({
            name: 'client.buy-program.step-3',
            query: { transaction_id: this.transactionId },
          }).catch()
        } else if (res.data.status == 'failed') {
          this.$toast({
            status: 'error',
            title: 'Peringatan',
            description: 'Transaksi tidak dapat dilanjutkan.',
            duration: 2000,
          })
          this.$router.push({
            name: 'client.profile.historyTransaction',
          })
        }
        if (this.transaction.paymentMethodId) {
          this.isAlreadySelectPayment = true
        }
      } catch (e) {
        this.destoryAllInterval()
        this.$toast({
          status: 'error',
          title: 'Error',
          description: e?.message ? e?.message : (e?.data.message ? e?.data.message : 'Transaksi tidak ditemukan'),
          duration: 3000,
        })
        this.$router.push('/')
      }
    },
    showCountdown() {
      const utc = moment(this.transaction.expiryAt).subtract(7, 'h').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
      const end = new Date(utc)
      this.countdownInterval = setInterval(() => {
        const now = new Date()
        const distance = end.getTime() - now.getTime()
        if (distance < 0) {
          this.isExpired = true
          this.destoryAllInterval()
          this.countdown = 'Melebihi batas waktu pembayaran'
          this.$toast({
            status: 'warning',
            title: 'Ooops...',
            description: 'Waktu pembayaran habis. Silakan melakukan pembelian ulang.',
            duration: 4000,
          })
          this.$router.push({
            name: 'client.profile.historyTransaction',
          })
          return
        }
        // const days = Math.floor(distance / this._days)
        const hours = Math.floor(distance / this._hours)
        const minutes = Math.floor((distance % this._hours) / this._minutes)
        const seconds = Math.floor((distance % this._minutes) / this._seconds)
        const displayMinutes = minutes < 10 ? '0' + minutes : minutes
        const displaySeconds = seconds < 10 ? '0' + seconds : seconds
        const displayHours = hours < 10 ? '0' + hours : hours
        // const displayDays = days < 10 ? 0 + days : days
        this.countdown = 'Selesaikan pembayaran dalam ' + displayHours + ':' + displayMinutes + ':' + displaySeconds
      }, 1000)  
    },
    async checkPayment() {
      this.loading = true
      await this.init()
      if (this.transaction.status == 'pending') {
        this.loading = false
        this.isOpenPaymentNotDone = true
      }
    },
    handleClosePaymentNotDone() {
      this.isOpenPaymentNotDone = false
    },
    toggleDetail() {
      if (this.isAlreadySelectPayment && !this.showPaymentMethod) {
        this.showDetailTransaction = !this.showDetailTransaction 
      }
    },
    createInitInterval() {
      this.initInterval = setInterval(() => {
        this.init()
      }, 5000)
    },
    destoryAllInterval() {
      clearInterval(this.countdownInterval)
      clearInterval(this.initInterval)
    },
    onOpenConfirm() {
      this.isConfirm = true
    },
    onCloseConfirm() {
      this.isConfirm = false
    },
    onSubmitConfirm() {
      this.onCloseConfirm()
      this.showPaymentMethod = true
      this.showDetailTransaction = false
    },
  },
}
</script>

<style scoped>
.icon-payment {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .icon-payment {
    width: 15px;
    height: 15px;
  }
}
</style>