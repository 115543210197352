var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#fff', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['auto', '74vh']
    }
  }, [_c('Stepper', {
    attrs: {
      "margin-top": "16px",
      "margin-bottom": ['12px', '20px'],
      "steps": [{
        label: 'Informasi Pribadi'
      }, {
        label: 'Periksa',
        isCurrentStep: true
      }, {
        label: 'Pembayaran'
      }]
    }
  }), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Pembayaran ")]), _c('c-box', {
    attrs: {
      "padding": ['16px 8px', '1rem'],
      "max-width": ['auto', '900px'],
      "margin": "auto"
    }
  }, [_vm.isAlreadySelectPayment ? _c('c-flex', {
    attrs: {
      "w": "100%",
      "background": "#FDEDCB",
      "padding": "8px",
      "mb": ['1rem', '1.5rem'],
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-watch.svg'),
      "width": ['20px', '25px'],
      "height": ['20px', '25px'],
      "mr": ['8px', '16px']
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "#111"
    }
  }, [_vm._v(" " + _vm._s(_vm.countdown) + " ")])], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "border-radius": "12px",
      "background-color": "#C7F9E3",
      "p": ['10px 20px', '1rem 2rem'],
      "mb": "1rem"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" Metode Pembayaran ")]), _vm.isAlreadySelectPayment ? _c('c-button', {
    attrs: {
      "variant": "link",
      "font-family": "Roboto",
      "color": "#D32737",
      "font-size": ['12px', '16px'],
      "font-weight": "400"
    },
    on: {
      "click": _vm.handleChangePaymentMethod
    }
  }, [_vm._v(" " + _vm._s(_vm.showPaymentMethod ? 'Kembali' : 'Ganti Metode') + " ")]) : _vm._e()], 1), _vm.isAlreadySelectPayment && !_vm.showPaymentMethod ? _c('c-box', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "background": "#FFFFFF",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "padding": ['16px', '24px'],
      "mb": ['1rem', '1.5rem'],
      "mx": "8px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" Pembayaran Melalui ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "border-bottom": "1px solid #C4C4C4",
      "my": "10px"
    }
  }), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.paymentMethodName) + " ")]), _vm.transaction.image ? _c('c-image', {
    attrs: {
      "src": _vm.transaction.image,
      "height": ['25px', '35px']
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('SelectPayment', {
    attrs: {
      "show": !_vm.isAlreadySelectPayment || _vm.showPaymentMethod,
      "transaction-id": _vm.transactionId,
      "transaction": _vm.transaction
    },
    on: {
      "afterSelectPayment": _vm.handleAfterSelectPayment
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "border-radius": "12px",
      "background-color": "#C7F9E3",
      "p": ['10px 20px', '1rem 2rem'],
      "mb": "1rem"
    },
    on: {
      "click": _vm.toggleDetail
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "20px"
    }
  }, [_vm._v(" Pembayaran ")]), _c('inline-svg', {
    staticClass: "icon-payment",
    style: _vm.showDetailTransaction ? 'transform: scaleY(-1)' : '',
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "fill": "#333333"
    }
  })], 1), _vm.isAlreadySelectPayment && _vm.showDetailTransaction ? _c('DetailTransaction', {
    attrs: {
      "transaction-id": _vm.transactionId,
      "transaction": _vm.transaction,
      "loading": _vm.loading
    },
    on: {
      "checkPayment": _vm.checkPayment
    }
  }) : _vm._e()], 1), _c('ModalConfirm', {
    attrs: {
      "title": "Apa kamu yakin ingin mengganti metode pembayaran?",
      "is-open": _vm.isConfirm
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": function submit($event) {
        return _vm.onSubmitConfirm();
      }
    }
  }), _c('ModalError', {
    attrs: {
      "is-open": _vm.isOpenPaymentNotDone,
      "image": require('@/assets/images/illustration-8-raw.jpg'),
      "title": "Pembayaran belum diterima",
      "note": "Pembayaranmu belum diterima, silahkan lakukan pembayaran. Jika kamu sudah melakukan pembayaran silahkan cek sekala berkala",
      "button-text": "Kembali",
      "button-icon": false,
      "button-action": function buttonAction() {
        _vm.handleClosePaymentNotDone();
      }
    },
    on: {
      "close": _vm.handleClosePaymentNotDone
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }