var render = function () {
  var _vm$transaction, _vm$transaction$produ, _vm$transaction2, _vm$transaction2$prod, _vm$transaction3, _vm$transaction4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "px": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "background": "#FFFFFF",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "padding": ['16px', '24px'],
      "mb": ['1rem', '1.5rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" No. Pesanan ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "border-bottom": "1px solid #C4C4C4",
      "my": "10px"
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.invoiceNumber) + " ")])], 1), _c('c-box', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "background": "#FFFFFF",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "padding": ['0px', '24px'],
      "mb": ['1rem', '1.5rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "mb": ['0px', '10px'],
      "p": ['16px', '0px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" Detail Pembayaran ")])], 1), _c('c-flex', {
    attrs: {
      "mb": "1rem",
      "px": ['1rem', '0'],
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.transaction.productPhoto,
      "h": ['100px', '150px'],
      "w": "auto",
      "border-radius": "12px",
      "flex-shrink": "0",
      "mr": ['10px', '24px']
    }
  }), _c('c-box', {
    attrs: {
      "align-self": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '28px'],
      "font-weight": [500, 700],
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.productName) + " " + _vm._s(_vm.transaction.productServiceName) + " (" + _vm._s(_vm.transaction.duration) + " Hari) ")]), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          textDecoration: 'underline'
        }
      },
      expression: "{\n            ':hover': {\n              textDecoration: 'underline',\n            }\n          }"
    }],
    attrs: {
      "as": "router-link",
      "font-family": "Roboto",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "color": "#333",
      "to": _vm.getHandleMore({
        isKonsultasi: ((_vm$transaction = _vm.transaction) === null || _vm$transaction === void 0 ? void 0 : (_vm$transaction$produ = _vm$transaction.productService) === null || _vm$transaction$produ === void 0 ? void 0 : _vm$transaction$produ.isKonsultasi) == 1,
        isExtend: ((_vm$transaction2 = _vm.transaction) === null || _vm$transaction2 === void 0 ? void 0 : (_vm$transaction2$prod = _vm$transaction2.productService) === null || _vm$transaction2$prod === void 0 ? void 0 : _vm$transaction2$prod.isExtend) == 1,
        productId: (_vm$transaction3 = _vm.transaction) === null || _vm$transaction3 === void 0 ? void 0 : _vm$transaction3.productId,
        productSlug: (_vm$transaction4 = _vm.transaction) === null || _vm$transaction4 === void 0 ? void 0 : _vm$transaction4.slug
      })
    }
  }, [_vm._v(" Selengkapnya ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "font-weight": "700",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.transaction.productPrice)) + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "border-top": ['1px solid #C4C4C4', 'none'],
      "w": "100%"
    }
  }), _vm.transaction.billerCode ? _c('c-box', {
    attrs: {
      "border-top": ['none', '1px solid #C4C4C4'],
      "p": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700'],
      "mb": ['4px', '8px']
    }
  }, [_vm._v(" Kode Perusahaan ")]), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.billerCode) + " ")]), _c('input', {
    ref: "company_code",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.transaction.billerCode
    }
  }), _c('c-box', {
    attrs: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.copyClipboard('company_code');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-copy.svg'),
      "width": ['20px', '25px'],
      "height": ['20px', '25px']
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.transaction.paymentMethodCode != 'gopay' ? _c('c-box', {
    attrs: {
      "border-top": ['none', '1px solid #C4C4C4'],
      "p": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700'],
      "mb": ['4px', '8px']
    }
  }, [_vm._v(" No. Virtual Account ")]), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.vaNumber) + " ")]), _c('input', {
    ref: "va_number",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.transaction.vaNumber
    }
  }), _c('c-box', {
    attrs: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.copyClipboard('va_number');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-copy.svg'),
      "width": ['20px', '25px'],
      "height": ['20px', '25px']
    }
  })], 1)], 1)], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "border-top": ['none', '1px solid #C4C4C4'],
      "p": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700'],
      "mb": ['4px', '8px']
    }
  }, [_vm._v(" Total Pembayaran ")]), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '28px'],
      "color": "#008C81",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.getTotal) + " ")]), _c('input', {
    ref: "total",
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.transaction.total
    }
  }), _c('c-box', {
    attrs: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.copyClipboard('total');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-copy.svg'),
      "width": ['20px', '25px'],
      "height": ['20px', '25px'],
      "cursor": "pointer"
    }
  })], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "background": "#FFFFFF",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "padding": ['0px', '24px'],
      "mb": ['1rem', '1.5rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "mb": ['0px', '10px'],
      "p": ['16px', '0px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" Langkah Pembayaran ")])], 1), _vm.instruction.length > 0 ? _c('c-box', {
    attrs: {
      "border-top": "1px solid #C4C4C4",
      "px": "16px",
      "pt": "16px",
      "pb": ['16px', '0px']
    }
  }, [_c('c-accordion', {
    attrs: {
      "allow-toggle": true
    }
  }, _vm._l(_vm.instruction, function (item, i) {
    return _c('c-accordion-item', {
      key: i,
      attrs: {
        "border": "none",
        "mb": "10px"
      }
    }, [_c('c-accordion-header', {
      attrs: {
        "background-color": "#F2F2F2",
        "border-radius": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "flex": "1",
        "text-align": "left",
        "font-family": "Roboto",
        "font-size": ['16px', '20px'],
        "font-weight": ['500', '700']
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('c-accordion-icon')], 1), item.instruction.length > 0 ? _c('c-accordion-panel', {
      attrs: {
        "pb": "2"
      }
    }, [_vm.transaction.qrCodeLink && item.type == 'qrcode' ? _c('c-image', {
      attrs: {
        "src": _vm.transaction.qrCodeLink,
        "max-width": "250px",
        "mx": "auto",
        "mb": "8px"
      }
    }) : _vm._e(), _c('c-text', {
      attrs: {
        "mb": "4px",
        "font-size": ['14px', '16px']
      }
    }, [_vm._v(" Petunjuk Pembayaran dengan " + _vm._s(item.title) + " ")]), _c('c-list', {
      attrs: {
        "as": "ol",
        "style-type": "decimal",
        "style-pos": "outside",
        "ml": "1rem"
      }
    }, _vm._l(item.instruction, function (step, index) {
      return _c('c-list-item', {
        key: index,
        attrs: {
          "font-family": "Roboto",
          "font-size": ['14px', '16px']
        },
        domProps: {
          "innerHTML": _vm._s(step)
        }
      });
    }), 1), _c('c-flex', {
      attrs: {
        "justify-content": "center"
      }
    }, [_vm.transaction.deepLink && item.type == 'deeplink' ? _c('c-button', {
      attrs: {
        "variant": "outline",
        "color": "#008C81",
        "border-color": "#008C81",
        "border-radius": "32px",
        "mt": "16px",
        "w": ['100%', '400px'],
        "font-size": ['14px', '16px'],
        "as": "a",
        "href": _vm.transaction.deepLink,
        "target": "_blank",
        "h": "50px"
      }
    }, [_vm._v(" Lanjutkan Pembayaran ")]) : _vm._e()], 1)], 1) : _vm._e()], 1);
  }), 1)], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "text-align": "center",
      "pb": ['16px', '0px']
    }
  }, [_c('c-button', {
    attrs: {
      "background": "#008C81",
      "color": "#FFF",
      "py": "10px",
      "border-radius": "60px",
      "font-size": "16px",
      "font-weight": "500",
      "font-family": "Roboto",
      "w": ['100%', '400px'],
      "h": "50px",
      "is-loading": _vm.loading,
      "loading-text": "Memperbarui Pembayaran"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('checkPayment');
      }
    }
  }, [_vm._v(" Cek Pembayaran ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }