var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('c-box', {
    attrs: {
      "mb": "1rem",
      "px": "8px"
    }
  }, [_c('PaymentMethod', {
    attrs: {
      "detail-payment": _vm.detailPayment,
      "loading": _vm.loading
    },
    on: {
      "handleChangePaymentMethod": _vm.handleChangePaymentMethod,
      "handleChangePaymentMethodId": _vm.handleChangePaymentMethodId
    },
    model: {
      value: _vm.selectedPayment,
      callback: function callback($$v) {
        _vm.selectedPayment = $$v;
      },
      expression: "selectedPayment"
    }
  }), _c('c-flex', {
    attrs: {
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "background": "#008C81",
      "color": "#FFF",
      "w": "400px",
      "font-family": "Roboto",
      "font-size": "16px",
      "font-weight": "500",
      "border-radius": "50px",
      "p": "16px 50px",
      "h": "50px",
      "disabled": _vm.isDisabledPayment,
      "is-loading": _vm.loading,
      "loading-text": "Memperbarui Pembayaran"
    },
    on: {
      "click": function click($event) {
        return _vm.submitPayment();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.paymentMethodId ? 'Ubah Metode Pembayaran' : 'Lanjutkan Pembayaran') + " ")])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }